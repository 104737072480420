import React, { useEffect, useMemo, useRef, useState } from "react";

import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import useLocalStorage from "use-local-storage";
import { useDispatch, useSelector } from "react-redux";

import {
  CLAIM_PROPS,
  checkDetailsInit,
} from "../../../../constants/ApplicationConstants.js";
import Loading from "../../../common/Loading.js";

import {
  updateClaimID,
  updateFlowCurrentStep,
  updateUploadValues,
} from "../../../../config/actions.js";
import { Request } from "../../../../config/requests.js";
import TableContainerComponent from "../../../../features/ocr/invoiceTable/tableComponents/TableContainerComponent.js";
import { getToken } from "../../../../config/token.js";
// import { attachToExistingClaim } from "../../../../services/clams.js";
import { useTranslation } from "react-i18next";
import useQuestionContainer from "../../../../hooks/useQuestionContainer.js";
import ErrorModal from "../../../common/errorModal.js";
import useCompleteFlow from "../../../../hooks/useCompleteFlow.js";

const ExistingClaim = ({
  data,
  index,
  claimIdTemp,
  ocrDetails,
  handleInput,
  details,
  isEditMode = false,
  ocrKey,
}) => {
  const [localLoad, setLocalLoad] = useState(true);
  const [tableDetails, setTableDetails] = useState([]);
  const [gotMatch, setGotMatch] = useState([]);
  const [gotDetails, setGotDetails] = useState([]);
  const [checkDetails, setCheckDetails] = useState(checkDetailsInit);
  const [themeSetting] = useLocalStorage("theme", "panda");
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { compleFlow } = useCompleteFlow();

  const flowQuestions = useSelector((s) => s.flow.flowQuestions);
  const currentStep = useSelector((s) => s.flow.flowCurrentStep);
  const currentPetInfo = useSelector((s) => s.flow.selectedPetInfo);
  const selectedImages = useSelector((s) => s.flow.uploadedImages);
  const selectedFlowType = useSelector((s) => s.flow.selectedFlowTypeToSkip);
  const existingTypeDocument = useSelector((s) => s.flow.existingTypeDocument);

  const { id } = useParams();
  const token = getToken();
  const ref = useRef(null);
  const [initialTableDetails, setInintialTableDetails] = useState([]);
  const [selectedTableDetails, setSelectedTableDetails] = useState([]);
  const [resultTableDetails, setResultTableDetails] = useState([]);
  const [markedRows, setMarkedRows] = useState([]);
  const [gotList, setGotList] = useState([]);
  const [petData, setPetData] = useState("");
  const [error, setError] = useState(false);
  const [updatingIndex, setUpdatingIndex] = useState(0);

  useEffect(() => {
    const options = [{ label: "-", value: "" }];
    gotDetails?.forEach((el) => {
      options.push({ label: el["GOT Nummer"], value: el["GOT Nummer"] });
    });
    setGotList(options);
  }, [gotDetails]);

  function getClaimsHistory(targetId) {
    Request({
      method: "get",
      route: `api/v2/claims/policy_claims/${targetId}`,
      token: token,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error("Request failed with status: " + res.status);
        }
      })
      .then(async (json) => {
        let dataWithoutNullID = json.data.filter(el =>
          el.claimPortalInfo &&
          el.claimPortalInfo.hasOwnProperty("invoiceDate") &&
          el.claimPortalInfo.hasOwnProperty("claimStatus"))
        setPetData(dataWithoutNullID || []);
        setSelectedTableDetails(dataWithoutNullID || []);
        setInintialTableDetails(dataWithoutNullID || []);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setLocalLoad();
      });
  }

  function rowSelectHandler(row) {
    // for "Subsequent invoice" we allow user select only one item
    if (existingTypeDocument === "Subsequent invoice") {
      setSelectedTableDetails((prevDetails) =>
        prevDetails.map((item, index) => ({
          ...item,
          selected:
            index === row
              ? true
              : false
        }))
      );
    } else {
      setSelectedTableDetails((prevDetails) =>
        prevDetails.map((item, index) => ({
          ...item,
          selected:
            index === row
              ? item.selected === true
                ? false
                : true
              : item.selected,
        }))
      );
    }
  }

  useEffect(() => {
    if (tableDetails) {
      setSelectedTableDetails(tableDetails);
      setMarkedRows(selectedTableDetails?.filter((el) => el.selected === true));
    }
  }, [tableDetails]);

  useEffect(() => {
    if (selectedTableDetails) {
      setMarkedRows(selectedTableDetails?.filter((el) => el.selected === true));
      setResultTableDetails(
        selectedTableDetails?.filter((el) => el.selected !== true)
      );
    }
  }, [selectedTableDetails]);

  const [questionContainer, buttonContainer] = useQuestionContainer(localLoad);

  useEffect(() => {
    getClaimsHistory(id);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [id]);

  function handleUpdate(index, key, event) {
    let newTableDetails = [...tableDetails];
    let val = null;
    if ([CLAIM_PROPS.PET, CLAIM_PROPS.DESCRIPTION].includes(key)) {
      val = event.target.value;
    } else if ([CLAIM_PROPS.GOT].includes(key)) {
      val = event;
    } else if ([CLAIM_PROPS.DATE].includes(key)) {
      const date = new Date(event);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      val = `${year}-${month}-${day}`;
    } else {
      val =
        parseFloat(event.target.value) > 0 ? parseFloat(event.target.value) : 0;
      if (key === CLAIM_PROPS.UNIT_PRICE) {
        if (val > 0) {
          newTableDetails[index].Amount = Number(
            (val * newTableDetails[index].Quantity).toFixed(2)
          );
        } else {
          newTableDetails[index].Amount = 0;
        }
      } else if (key === CLAIM_PROPS.QUANTITY) {
        if (val > 0) {
          newTableDetails[index].Amount = Number(
            (val * newTableDetails[index].UnitPrice).toFixed(2)
          );
        } else {
          newTableDetails[index].Amount = 0;
        }
      }
    }
    newTableDetails[index][key] = val;
    setTableDetails(newTableDetails);

    let itemsTotal = newTableDetails
      .map((d) => d.Amount)
      .reduce(function (a, b) {
        return a + b;
      }, 0);

    let newCheckDetails = { ...checkDetails.total };
    newCheckDetails.values.SubTotal = itemsTotal.toFixed(2);
    newCheckDetails.values.TotalTax = (itemsTotal * 0.19).toFixed(2);
    newCheckDetails.values.InvoiceTotal = (itemsTotal * 1.19).toFixed(2);

    setCheckDetails({
      ...checkDetails,
      total: newCheckDetails,
    });
  }

  function handleDelete(index) {
    let newTableDetails = [...tableDetails];
    newTableDetails.splice(index, 1);
    setTableDetails(newTableDetails);
    Request({
      method: "post",
      route: "api/claims/update-claim",
      values: {
        id: claimIdTemp ? claimIdTemp : data.claimId,
        tableDetails: newTableDetails,
      },
    });

    let itemsTotal = newTableDetails
      .map((d) => d.Amount)
      .reduce(function (a, b) {
        return a + b;
      }, 0);

    let newCheckDetails = { ...checkDetails.total };
    newCheckDetails.values.SubTotal = itemsTotal.toFixed(2);
    newCheckDetails.values.TotalTax = (itemsTotal * 0.19).toFixed(2);
    newCheckDetails.values.InvoiceTotal = (itemsTotal * 1.19).toFixed(2);

    setCheckDetails({
      ...checkDetails,
      total: newCheckDetails,
    });
  }

  function handleNew() {
    let newTableDetails = [...tableDetails];
    newTableDetails.push({
      Amount: 0,
      Description: "",
      Quantity: 1,
      Tax: 19,
      UnitPrice: 0,
      Pet: currentPetInfo?.name,
      Date: "",
      GOT: "",
    });
    setTableDetails(newTableDetails);
    Request({
      method: "post",
      route: "api/claims/update-claim",
      values: {
        id: claimIdTemp ? claimIdTemp : data.claimId,
        tableDetails: newTableDetails,
      },
    });
  }

  async function approveTable() {
    setLocalLoad(true);
    const dropwDownAnswer =
      flowQuestions[0][0]?.content.answer || selectedFlowType;

    const idArray = markedRows
      .map((obj) => obj.id || obj.documents[0].payload.claimId)
      .filter((id) => id !== undefined && id !== null);
    
    dispatch(updateClaimID(idArray));

    try {
      // temporary hidden request
      // await attachToExistingClaim(idArray, dropwDownAnswer, selectedImages);
      dispatch(updateFlowCurrentStep(currentStep + 1));

      const isFinalStep = currentStep === flowQuestions.length - 1;
      if (isFinalStep) {
        // in case of existingTypeDocument === "Subsequent invoice" when no Payment step after it
         await compleFlow(...idArray);
      }
      window.scrollTo({ top: 0, behavior: "smooth" });
    } catch (e) {
      console.error(e);
    } finally {
      setLocalLoad(false);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }

  const backHandler = () => {
    handleInput([], index - 1);
    const updatedDetails = {
      ...ocrDetails,
      details: {
        ...ocrDetails.details,
        SubTotal: {
          ...ocrDetails.details.SubTotal,
          value: {
            ...ocrDetails.details.SubTotal.value,
            amount: checkDetails.total.values.SubTotal,
          },
        },
        TotalTax: {
          ...ocrDetails.details.TotalTax,
          value: {
            ...ocrDetails.details.TotalTax.value,
            amount: checkDetails.total.values.TotalTax,
          },
        },
        InvoiceTotal: {
          ...ocrDetails.details.InvoiceTotal,
          value: {
            ...ocrDetails.details.InvoiceTotal.value,
            amount: checkDetails.total.values.InvoiceTotal,
          },
        },
        Items: {
          ...ocrDetails.details.Items,
          values: tableDetails.map((el) => {
            return { properties: el };
          }),
        },
      },
    };
    dispatch(updateUploadValues({ [ocrKey]: updatedDetails }));
  };

  return (
    <>
      {localLoad && (
        <div className="QuestionContainer">
          <Loading noText subText={t("loaderSubText2")} />
        </div>
      )}

      {!localLoad && (
        <div className="InvoiceTableCointainer">

          {/* Previous version of back/next buttons below */}

          {/* {!isEditMode && (
            <div
              className="NavButtonContainer NavButtonContainer_initial"
              style={{ margin: "0" }}
              ref={buttonContainer}
            >
              <FormButton
                name="Back"
                route="back"
                handleInput={backHandler}
                // handleInput={handleInput}
                selection={[]}
                index={index}
                details={details.entry}
                condition={true}
              />
              <div className="Distance"></div>
              <FormButton
                name={
                  flowQuestions.length - 1 === currentStep ? "Finish" : "Next"
                }
                route="next"
                handleInput={approveTable}
                selection={[]}
                index={index}
                details={details.entry}
                condition={true}
                ref={ref}
                hideNext={true}
              />
            </div>
          )} */}

          <TableContainerComponent
            data={petData}
            gotDetails={gotDetails}
            gotMatch={gotMatch}
            tableDetails={tableDetails}
            handleUpdate={handleUpdate}
            handleDelete={handleDelete}
            handleNew={handleNew}
            themeSetting={themeSetting}
            setTableDetails={setTableDetails}
            selectedTableDetails={selectedTableDetails}
            resultTableDetails={resultTableDetails}
            initialTableDetails={initialTableDetails}
            rowSelectHandler={rowSelectHandler}
            updatingIndex={updatingIndex}
            setUpdatingIndex={setUpdatingIndex}
            gotList={gotList}
            ref={ref}
            isOnlySelectable={true}
            onBackHandler={() => handleInput([], index - 1)}
            backHandler={backHandler}
            approveTable={approveTable}
          />
          {error && (
            <ErrorModal
              openModal={error}
              setErrorModal={() => setError(!error)}
              isRedirect={false}
            />
          )}
        </div>
      )}
    </>
  );
};

export default ExistingClaim;
