import { Request } from "../config/requests";
import { getToken } from "../config/token";

const token = getToken();

export async function attachToExistingClaim(ids, note, images) {
  const fd = new FormData();
  images.forEach((i) => fd.append("files", i));

  for (const id of ids) {
    try {
      Request({
        method: "post",
        token: token,
        route: `api/v2/claims/attach_files/${id}?note=${note}`,
        formData: fd,
      });
    } catch (e) {
      console.error(e);
    }
  };
}

export async function createDuplicateClaim(duplicatesList, claimsId) {
  const formattedIds = duplicatesList.split(",");

  try {
    return Request({
      method: "post",
      token: token,
      route: `api/claims/duplicate_claim`,
      values: {
        policy_id: claimsId,
        claim_id: formattedIds[0] || formattedIds,
      },
    });
  } catch (e) {
    console.error(e);
  }
}

export async function checkLastClaim(policyId) {
  try {
    // return new Promise(resolve => {
    //   setTimeout(() => {
    //     return resolve(false)
    //   }, 2000)
    // })
    return Request({
      method: "get",
      token: token,
      route: `api/claims/verify_last_claim/${policyId}`,
    }).then((response) => response.json());
  } catch (e) {
    console.error(e);
  }
}

export async function getAttachedDocs(row, currentInd, asBlob = false) {
  try {
    const endpoint = `api/v2/claims/${row?.documents[0]?.payload?.claimId}/documents/${row?.documents[currentInd]?.id}`;
    if (asBlob) {
      // Retrieve the file as Blob
      const response = await Request({
        method: "get",
        route: `${endpoint}/download`,
        token,
      });
      const blob = await response.blob();
      return {
        blob: blob,
        type: response.headers.get("content-type"),
        error: null,
      };
    } else {
      // Retrieve the file as base64
      const response = await Request({
        method: "get",
        route: `${endpoint}/base64`,
        token,
      });
      const json = await response.json();
      return {
        data: json.data,
        type: json.type,
        error: null,
      };
    }
  } catch (e) {
    console.error(e);
  }
}
