import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ModalPreview from "./modals/ModalPreview";
import { useTranslation } from "react-i18next";
import {
  Box,
  Pagination,
  PaginationItem,
  Tooltip,
} from "@mui/material";
import ShowFileIcon from "../../assets/iconsSmall/list.svg";
import ShowDoc from "../../assets/iconsSmall/document.svg";
import Lightning from "../../assets/iconsSmall/lightning.svg";
import PandaMain from "../../assets/UserInfo/panda-logo-main.svg";
import SortAsc from "../../assets/iconsSmall/sortAsc.svg";
import ArrowLeft from "../../assets/PetPage/arrow_left_dashed_small.svg";
import ArrowRight from "../../assets/PetPage/arrow_right_dashed.svg";
import ArrowDownFatWhite from "../../assets/iconsSmall/ep_arrow-down_white.svg";
import ArrowUpFatBrown from "../../assets/iconsSmall/ep_arrow-up_brown.svg";
import DownloadIcon from "../../assets/PetPage/download_icon.svg";
import { ReactComponent as ArrowLeftRed } from "../../assets/iconsSmall/arrow_left_solid_red.svg";
import { ReactComponent as ArrowRightRed } from "../../assets/iconsSmall/arrow_right_solid_red.svg";
import {
  claimsTableHeaders,
  claimsTableHeadersAdmin,
  formatAnyDate,
  formatTableDate,
  getDateGE,
} from "../../config/helpers";
import { isMobile } from "react-device-detect";
import ModalAdvice from "./modals/ModalAdvice";
import { USER_MODE } from "../../constants/ApplicationConstants";
import { useTableScroll } from "../../hooks/useTableScroll";
import breedListDogs from "../../assets/dog_breeds.json";
import breedListCats from "../../assets/cat_breeds.json";
import { getAttachedDocs } from "../../services/clams";
import Loading from "../../components/common/Loading";

function PolicyTable({
  policyData,
  setCurrentClaimNo,
  setOpenModalInfo,
  mode,
}) {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalAdviceOpen, setIsModalAdviceOpen] = useState(false);
  const [advice, setAdvice] = useState("");
  const [currentRow, setCurrentRow] = useState({});
  const [expandedRow, setExpandedRow] = useState(null);
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [sortedData, setSortedData] = useState(policyData);
  const [currentClaim, setCurrentClaim] = useState(1);
  const [downloadingAttach, setDownloadingAttach] = useState(false);
  const [downloadingIndex, setDownloadingIndex] = useState(null);

  const handlePage = (event, value) => {
    setCurrentClaim(value);
  };

  const openMore = (index, e) => {
    e.stopPropagation();
    setCurrentClaimNo(index);
    setOpenModalInfo(true);
  };

  const decreasePageNumber = () => {
    if (currentClaim > 1) {
      setCurrentClaim(currentClaim - 1);
    }
  };

  const increasePageNumber = () => {
    if (currentClaim < sortedData.length) {
      setCurrentClaim(currentClaim + 1);
    }
  };

  const handleSort = (column) => {
    const isAsc = sortedColumn === column && sortDirection === "asc";
    setSortDirection(isAsc ? "desc" : "asc");
    setSortedColumn(column);
    const sorted = [...policyData].sort((a, b) => {
      let valueA, valueB;

      if (column === "invoiceDate") {
        valueA = formatTableDate(a?.claimPortalInfo?.invoiceDate);
        valueB = formatTableDate(b?.claimPortalInfo?.invoiceDate);
      } else if (column === "claimNo") {
        valueA =
          a.claimNo ||
          t("noInfo");
        valueB =
          b.claimNo ||
          t("noInfo");
      } else if (column === "diagnosis") {
        valueA =
          a.claimPortalInfo?.diagnosis ||
          t("noInfo");
        valueB =
          b.claimPortalInfo?.diagnosis ||
          t("noInfo");
      } else if (column === "petName") {
        valueA = a.policy?.insuredItem?.nameOfPet || t("noInfo");
        valueB = b.policy?.insuredItem?.nameOfPet || t("noInfo");
      } else if (column === "Status") {
        valueA = a.claimPortalInfo?.claimStatus.toLowerCase();
        valueB = b.claimPortalInfo?.claimStatus.toLowerCase();
      } else if (column === "veterinarian") {
        valueA =
          a.claimPortalInfo?.vendorName ||
          t("noInfo");
        valueB =
          b.claimPortalInfo?.vendorName ||
          t("noInfo");
      }

      if (column === "invoiceDate") {
        // Compare dates if both are valid dates
        if (valueA && valueB) {
          valueA = new Date(valueA);
          valueB = new Date(valueB);
          if (valueA < valueB) return isAsc ? 1 : -1;
          if (valueA > valueB) return isAsc ? -1 : 1;
          return 0;
        }
        // Handle cases where one or both dates are invalid
        if (!valueA) return isAsc ? 1 : -1;
        if (!valueB) return isAsc ? -1 : 1;
      } else {
        // Handle other columns as strings
        if (valueA < valueB) return isAsc ? 1 : -1;
        if (valueA > valueB) return isAsc ? -1 : 1;
        return 0;
      }
    });

    setSortedData(sorted);
  };

  const breedParse = (breed) => {
    if (breed) {
      let spices =
        breed.split("_")[0] === "DOG" ? breedListDogs : breedListCats;
      let result = spices.find((el) => el.value === breed)?.value_name;
      if (result) return result === "Unbekannt" ? t("Unbekannt") : result;
    }
    return breed === "Unbekannt" ? t("Unbekannt") : breed;
  };

  const getAttachedFile = async (row, currentInd) => {
    try {
      setDownloadingAttach(true);
      setDownloadingIndex(currentInd);
      const { blob, type } = await getAttachedDocs(row, currentInd, true);
      if (blob) {
        const downloadUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        let filename = 'filename';
        if (type === 'application/pdf') {
          filename += '.pdf';
        } else if (type === 'image/png') {
          filename += '.png';
        } else if (type === 'image/jpeg') {
          filename += '.jpg';
        } else {
          filename += '.file';
        }
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(downloadUrl);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setDownloadingAttach(false);
      setDownloadingIndex(null);
    }
  };

  useEffect(() => {
    setSortedData([...policyData]);
    handleSort("invoiceDate");
    recalculate();
  }, [policyData]);

  const handleSetRow = (id, index) => {
    setExpandedRow(expandedRow === index ? null : index);
    recalculate();
  };

  const { bodyRef, headerRef, syncScroll, listOfItemsRef, recalculate } =
    useTableScroll();

  return (
    <>
      {!isMobile && (
        <img
          src={PandaMain}
          alt="Panda_Main_Logo"
          className="main-logo-policy"
        />
      )}
      {isModalAdviceOpen && (
        <ModalAdvice
          isModalAdviceOpen={isModalAdviceOpen}
          setIsModalAdviceOpen={setIsModalAdviceOpen}
          advice={advice}
        />
      )}
      {isModalOpen && (
        <ModalPreview
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          row={currentRow}
        />
      )}

      {/* TABLE */}

      {isMobile ? (
        <div className="SlowShow policy-table-mobile">
          <div className="policy-table-mobile__header">
            <div
              className="policy-table-mobile__back"
              style={currentClaim == 1 ? { opacity: "0.5" } : {}}
            >
              <div
                className="button_red_mobile back margin-0"
                onClick={decreasePageNumber}
              >
                <img src={ArrowLeft} alt="Back" />
                {t("back")}
              </div>
            </div>
            <div className="policy-table-mobile__img">
              <img
                src={PandaMain}
                alt="Panda_Main_Logo"
                className="main-logo-policy__mobile"
              />
            </div>
            <div
              className="policy-table-mobile__next"
              style={currentClaim == sortedData.length ? { opacity: "0.5" } : {}}
            >
              <div
                className="button_red_mobile back margin-0"
                onClick={increasePageNumber}
              >
                {t("next")}
                <img src={ArrowRight} alt="Next" />
              </div>
            </div>
          </div>
          <div className="policy-table-mobile__row">
            <div className="policy-table-mobile__left">{t("Date")}</div>
            <div className="policy-table-mobile__right">
              {formatAnyDate(sortedData[currentClaim - 1]?.claimPortalInfo?.invoiceDate) || t("noInfo")}
            </div>
          </div>
          <div className="policy-table-mobile__row">
            <div className="policy-table-mobile__left">{t("claimNo")}</div>
            <div className="policy-table-mobile__right">
              {sortedData[currentClaim - 1]?.claimNo || t("noInfo")}
            </div>
          </div>
          {mode === USER_MODE.ADMIN && (
            <div className="policy-table-mobile__row">
              <div className="policy-table-mobile__left">{t("petName")}</div>
              <div className="policy-table-mobile__right">
                {sortedData[currentClaim - 1]?.policy?.insuredItem?.nameOfPet || t("noInfo")}
              </div>
            </div>
          )}
          <div className="policy-table-mobile__row">
            <div className="policy-table-mobile__left">{t("diagnosis")}</div>
            <div className="policy-table-mobile__right">
              {(sortedData[currentClaim - 1]?.claimPortalInfo.claimType === "Liability claim"
                ? t("claimLC")
                : sortedData[currentClaim - 1]?.claimPortalInfo?.diagnosis) ||
                t("noInfo")}
            </div>
          </div>
          <div className="policy-table-mobile__row">
            <div className="policy-table-mobile__left">{t("veterinarian")}</div>
            <div className="policy-table-mobile__right">
              {(sortedData[currentClaim - 1]?.claimPortalInfo.claimType === "Liability claim"
                ? "-"
                : sortedData[currentClaim - 1]?.claimPortalInfo?.vendorName) ||
                t("noInfo")}
            </div>
          </div>
          <div className="policy-table-mobile__row">
            <div className="policy-table-mobile__left">{t("Status")}</div>
            <div className="policy-table-mobile__right">
              {sortedData[currentClaim - 1]?.claimPortalInfo?.claimStatus || t("noInfo")}
            </div>
          </div>
          <div className="policy-table-mobile__row">
            <div className="policy-table-mobile__left">{t("Details")}</div>
            <div
              className="policy-table-mobile__right"
              style={{ padding: "6px 0" }}
            >
              <div className="d-flex justify-content-evenly align-items-center">
                {mode === USER_MODE.ADMIN && (
                  <>
                    {sortedData[currentClaim - 1]?.adjudication ? (
                      <Tooltip title={t("seeAdvice")} placement="top">
                        <div
                          className="back_icon_red mobile"
                          onClick={() => {
                            setAdvice(
                              sortedData[currentClaim - 1]?.adjudication
                            );
                            setIsModalAdviceOpen(true);
                          }}
                        >
                          <img src={Lightning} />
                        </div>
                      </Tooltip>
                    ) : (
                      <Tooltip title={t("seeAdvice")} placement="top">
                        <div
                          className="back_icon_red mobile"
                          onClick={(e) => e.stopPropagation()}
                          style={{ opacity: "50%" }}
                        >
                          <img src={Lightning} />
                        </div>
                      </Tooltip>
                    )}
                  </>
                )}
                {sortedData[currentClaim - 1]?.documents &&
                  sortedData[currentClaim - 1]?.documents.length > 0
                  ? (
                    <Tooltip title={t("seeInitDoc")} placement="top">
                      <div
                        className="back_icon_red mobile"
                        onClick={() => {
                          setCurrentRow(sortedData[currentClaim - 1]);
                          setIsModalOpen(true);
                        }}
                      >
                        <img src={ShowFileIcon} />
                      </div>
                    </Tooltip>
                  ) : (
                    <Tooltip title={t("seeInitDoc")} placement="top">
                      <div
                        className="back_icon_red mobile"
                        onClick={(e) => e.stopPropagation()}
                        style={{ opacity: "50%" }}
                      >
                        <img src={ShowFileIcon} />
                      </div>
                    </Tooltip>
                  )}
                {((sortedData[currentClaim - 1]?.data && sortedData[currentClaim - 1]?.data.length > 0) ||
                  (sortedData[currentClaim - 1]?.claimPortalInfo?.lineItemsAmount && sortedData[currentClaim - 1]?.claimPortalInfo?.lineItemsAmount > 0))
                  ? (
                    <Tooltip title={t("seeLineItems")} placement="top">
                      <div
                        className="back_icon_red mobile"
                        onClick={(e) =>
                          openMore(sortedData[currentClaim - 1].id, e)
                        }
                      >
                        <img src={ShowDoc} />
                      </div>
                    </Tooltip>
                  ) : (
                    <Tooltip title={t("seeLineItems")} placement="top">
                      <div
                        className="back_icon_red mobile"
                        onClick={(e) => e.stopPropagation()}
                        style={{ opacity: "50%" }}
                      >
                        <img src={ShowDoc} />
                      </div>
                    </Tooltip>
                  )}
              </div>
            </div>
          </div>

          <div
            className="policy-table-mobile__button"
            style={{
              color: expandedRow === currentClaim - 1 ? "#B29478" : "var(--b-color-text-primary-2)",
              backgroundColor: expandedRow === currentClaim - 1 ? "var(--b-color-bg-main-1)" : "var(--b-color-main-2)",
            }}
            onClick={() => handleSetRow(sortedData[currentClaim - 1]?.id, currentClaim - 1)}
          >
            <div>{t("detailedView")}</div>
            <img src={expandedRow === currentClaim - 1 ? ArrowUpFatBrown : ArrowDownFatWhite} alt="Arrow" />
          </div>

          {expandedRow === currentClaim - 1 && (
            <div className="policy-table-mobile__row">
              <div className="policy-table-mobile__full-width">
                <div className="reset-table-new__info__container">
                  <div className="reset-table-new__info__block">
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <div style={{ marginBottom: 0 }}>{t("petInfo")}</div>
                    </Box>
                    <ul>
                      <li>
                        {t("petName")}:{" "}
                        {sortedData[currentClaim - 1]?.policy?.insuredItem?.nameOfPet || t("noInfo")}
                      </li>
                      <li>
                        {t("chipNumber")}:{" "}
                        {(sortedData[currentClaim - 1]?.policy?.insuredItem?.chipNumber &&
                          sortedData[currentClaim - 1]?.policy?.insuredItem?.chipNumber != "")
                          ? sortedData[currentClaim - 1]?.policy?.insuredItem?.chipNumber
                          : t("noInfo")}
                      </li>
                      <li>
                        {t("incidentDate")}:{" "}
                        {formatAnyDate(sortedData[currentClaim - 1]?.claimPortalInfo?.invoiceDate) ||
                          t("noInfo")}
                      </li>
                    </ul>
                  </div>
                  <div className="reset-table-new__info__block">
                    <div>{t("ContractInformation")}</div>
                    <ul>
                      <li>
                        {t("policyNo")}:{" "}
                        {sortedData[currentClaim - 1]?.policyGeneral?.policyNo || t("noInfo")}
                      </li>
                      <li>
                        {t("tariff")}:{" "}
                        {`${sortedData[currentClaim - 1]?.policy?.products?.selectedPackage} (${(sortedData[currentClaim - 1]?.policy?.products?.selectedCoverage) * 100}%)` || t("noInfo")}
                      </li>
                    </ul>
                  </div>
                  <div className="reset-table-new__info__block">
                    <div>{t("AdditionalProperties")}</div>
                    <ul>
                      {sortedData[currentClaim - 1]?.claimPortalInfo?.additionalInfo.map((el, ind) => (
                        <li key={ind}>{el.key} - <span>{getDateGE(el.content, true)}</span></li>
                      ))}
                    </ul>
                  </div>
                  <div className="reset-table-new__info__block">
                    <div>{t("submittedDocs")}</div>
                    {sortedData[currentClaim - 1]?.documents.map((el, ind) => (
                      <div key={ind}>
                        {(downloadingAttach && downloadingIndex === ind)
                          ? <div className="d-flex flex-row justify-content-start">
                            <Loading noText fast scroll={false} />
                          </div>
                          : <div
                            className="documents_download__link"
                            onClick={() => getAttachedFile(sortedData[currentClaim - 1], ind, true)}
                          >
                            <div className="documents_download__link__circle additionall-item">
                              <img src={DownloadIcon} />
                            </div>
                            <div className="documents_download__link__bar additionall-item">
                              {`${t("Document")} ${ind + 1}`}
                            </div>
                          </div>}
                        <div style={{
                          fontSize: "14px",
                          padding: "0 0 0 10px",
                        }}>
                          {t("submittedOn") + getDateGE(el?.metadata?.uploadedAt)}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
          {sortedData.length > 1 && (
            <div className="policy-table-mobile__pagination">
              <Pagination
                className="reset-pagination"
                count={sortedData.length}
                page={currentClaim}
                siblingCount={1}
                boundaryCount={1}
                onChange={handlePage}
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: ArrowLeftRed, next: ArrowRightRed }}
                    {...item}
                  // sx={{
                  //   color: '#FFF',
                  //   backgroundColor: item.type === 'page' && item.page === currentClaim ? '#FE4B4E' : '#DF9F9B',
                  // }}
                  />
                )}
              />
            </div>
          )}
        </div>
      ) : (
        <div>
          <TableContainer
            className="reset-table-spacing table-reset-header"
            sx={{ margin: "0 0 -3px 0" }}
            ref={headerRef}
          >
            <Table
              className={`SlowShow reset-table-new ${mode === USER_MODE.ADMIN ? "policy-table-admin" : "policy-table"
                }`}
            >
              <TableHead>
                <TableRow className="reset-table-new__header">
                  {(mode === USER_MODE.ADMIN
                    ? claimsTableHeadersAdmin
                    : claimsTableHeaders
                  ).map((header, index) => (
                    <TableCell
                      key={`header-${index}`}
                      align={index > 0 ? "inherit" : "left"}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                          justifyContent: "center",
                        }}
                      >
                        <div>
                          <span>{t(header.title)}</span>
                          {header.sort && mode === USER_MODE.ADMIN && (
                            <img
                              alt=""
                              style={{ cursor: "pointer" }}
                              src={SortAsc}
                              onClick={() => handleSort(header.title)}
                            />
                          )}
                        </div>
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>

          <TableContainer
            className="reset-table-spacing"
            sx={{ maxHeight: 314 }}
            ref={bodyRef}
            onScroll={syncScroll}
          >
            <Table
              className={`SlowShow reset-table-new ${mode === USER_MODE.ADMIN ? "policy-table-admin" : "policy-table"
                }`}
            >
              <TableBody ref={listOfItemsRef}>
                {sortedData?.map((row, index) => (
                  <>
                    <TableRow
                      key={`row-${index}`}
                      className="reset-table-new__body"
                      onClick={(e) => handleSetRow(row.id, index)}
                    >
                      <TableCell key={0}>
                        <div>
                          <span>
                            {formatAnyDate(row?.claimPortalInfo?.invoiceDate) || t("noInfo")}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell key={1}>
                        <div>
                          <span>{row?.claimNo || t("noInfo")}</span>
                        </div>
                      </TableCell>
                      <TableCell key={2}>
                        <div>
                          <span>
                            {(row?.claimPortalInfo.claimType === "Liability claim"
                              ? t("claimLC")
                              : row?.claimPortalInfo?.diagnosis) ||
                              t("noInfo")}
                          </span>
                        </div>
                      </TableCell>
                      {mode === USER_MODE.ADMIN && (
                        <TableCell key={3}>
                          <div>
                            <span>
                              {row?.policy?.insuredItem?.nameOfPet ||
                                t("noInfo")}
                            </span>
                          </div>
                        </TableCell>
                      )}
                      <TableCell key={4}>
                        <div>
                          <span>
                            {(row?.claimPortalInfo.claimType === "Liability claim"
                              ? "-"
                              : row?.claimPortalInfo?.vendorName) ||
                              t("noInfo")}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell key={5}>
                        <div>
                          <span>{row?.claimPortalInfo?.claimStatus}</span>
                        </div>
                      </TableCell>
                      <TableCell key={6} sx={{ padding: "0 5px" }}>
                        <div className="d-flex flex-row justify-content-center">
                          {mode === USER_MODE.ADMIN && (
                            <>
                              {row?.adjudication ? (
                                <Tooltip title={t("seeAdvice")} placement="top">
                                  <div
                                    className="back_icon_red"
                                    style={{ marginRight: "10px" }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setAdvice(row?.adjudication);
                                      setIsModalAdviceOpen(true);
                                    }}
                                  >
                                    <img
                                      src={Lightning}
                                      style={{
                                        color: "#FFFAF0",
                                        top: "3px",
                                        position: "relative",
                                      }}
                                    />
                                  </div>
                                </Tooltip>
                              ) : (
                                <Tooltip title={t("seeAdvice")} placement="top">
                                  <div
                                    className="back_icon_red"
                                    style={{
                                      marginRight: "10px",
                                      opacity: "50%",
                                    }}
                                    onClick={(e) => e.stopPropagation()}
                                  >
                                    <img
                                      src={Lightning}
                                      style={{
                                        color: "#FFFAF0",
                                        top: "3px",
                                        position: "relative",
                                        opacity: "50%",
                                      }}
                                    />
                                  </div>
                                </Tooltip>
                              )}
                            </>
                          )}
                          {row?.documents &&
                            row?.documents.length > 0
                            ? (
                              <Tooltip title={t("seeInitDoc")} placement="top">
                                <div
                                  className="back_icon_red"
                                  style={{ marginRight: "10px" }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setCurrentRow(row);
                                    setIsModalOpen(true);
                                  }}
                                >
                                  <img
                                    src={ShowFileIcon}
                                    style={{
                                      color: "#FFFAF0",
                                      top: "5px",
                                      position: "relative",
                                    }}
                                  />
                                </div>
                              </Tooltip>
                            ) : (
                              <Tooltip title={t("seeInitDoc")} placement="top">
                                <div
                                  className="back_icon_red"
                                  style={{ marginRight: "10px", opacity: "50%" }}
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  <img
                                    src={ShowFileIcon}
                                    style={{
                                      color: "#FFFAF0",
                                      top: "5px",
                                      position: "relative",
                                      opacity: "50%",
                                    }}
                                  />
                                </div>
                              </Tooltip>
                            )}
                          {((row?.data && row?.data.length > 0) ||
                            (row?.claimPortalInfo?.lineItemsAmount && row?.claimPortalInfo?.lineItemsAmount > 0))
                            ? (
                              <Tooltip title={t("seeLineItems")} placement="top">
                                <div
                                  className="back_icon_red"
                                  style={{ marginRight: "0", opacity: "100%" }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    openMore(row?.id, e);
                                  }}
                                >
                                  <img
                                    src={ShowDoc}
                                    style={{
                                      color: "#FFFAF0",
                                      top: "5px",
                                      position: "relative",
                                      opacity: "100%",
                                    }}
                                  />
                                </div>
                              </Tooltip>
                            ) : (
                              <Tooltip title={t("seeLineItems")} placement="top">
                                <div
                                  className="back_icon_red"
                                  style={{ marginRight: "0", opacity: "50%" }}
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  <img
                                    src={ShowDoc}
                                    style={{
                                      color: "#FFFAF0",
                                      top: "5px",
                                      position: "relative",
                                      opacity: "50%",
                                    }}
                                  />
                                </div>
                              </Tooltip>
                            )}
                        </div>
                      </TableCell>
                    </TableRow>
                    {expandedRow === index && (
                      <TableRow className="reset-table-new__info relative">
                        <TableCell colSpan={9}>
                          <button
                            className="btn-go-top"
                            onClick={() => {
                              setExpandedRow(null);
                            }}
                          >
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M10 3.33325L5 8.33325M10 3.33325L15 8.33325M10 3.33325V12.0833M10 16.6666V14.5833"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                          <div className="reset-table-new__info__container">
                            <div className="reset-table-new__info__block">
                              <div>{t("petInfo")}</div>
                              <ul>
                                <li>
                                  {t("petName")}:{" "}
                                  {row?.policy?.insuredItem?.nameOfPet ||
                                    t("noInfo")}
                                </li>
                                <li>
                                  {t("chipNumber")}:{" "}
                                  {(row?.policy?.insuredItem?.chipNumber &&
                                    row?.policy?.insuredItem?.chipNumber != "")
                                    ? row?.policy?.insuredItem?.chipNumber
                                    : t("noInfo")}
                                </li>
                                <li>
                                  {t("incidentDate")}:{" "}
                                  {formatAnyDate(row?.claimPortalInfo?.invoiceDate) || t("noInfo")}
                                </li>
                              </ul>
                            </div>
                            <div className="reset-table-new__info__block">
                              <div>{t("ContractInformation")}</div>
                              <ul>
                                <li>
                                  {t("policyNo")}:{" "}
                                  {row?.policyGeneral?.policyNo || t("noInfo")}
                                </li>
                                <li>
                                  {t("tariff")}:{" "}
                                  {`${t(row?.policy?.products?.selectedPackage)} (${(row?.policy?.products?.selectedCoverage) * 100}%)` || t("noInfo")}
                                </li>
                              </ul>
                            </div>
                            <div className="reset-table-new__info__block">
                              <div>{t("AdditionalProperties")}</div>
                              <ul>
                                {row?.claimPortalInfo?.additionalInfo.map((el, ind) => (
                                  <li key={ind}>{el.key} - <span>{getDateGE(el.content, true)}</span></li>
                                ))}
                              </ul>
                            </div>
                            <div className="reset-table-new__info__block">
                              <div>{t("submittedDocs")}</div>
                              {row?.documents.map((el, ind) => (
                                <div key={ind}>
                                  {(downloadingAttach && downloadingIndex === ind)
                                    ? <div className="d-flex flex-row justify-content-start">
                                      <Loading noText fast scroll={false} />
                                    </div>
                                    : <div
                                      className="documents_download__link"
                                      onClick={() => getAttachedFile(row, ind, true)}
                                    >
                                      <div className="documents_download__link__circle additionall-item">
                                        <img src={DownloadIcon} />
                                      </div>
                                      <div className="documents_download__link__bar additionall-item">
                                        {`${t("Document")} ${ind + 1}`}
                                      </div>
                                    </div>
                                  }
                                  <div style={{
                                    fontSize: "14px",
                                    padding: "0 0 0 10px",
                                  }}>
                                    {t("submittedOn") + getDateGE(el?.metadata?.uploadedAt)}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </>
  );
}

export default PolicyTable;
