import React, {
  forwardRef,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { t } from "i18next";
import TileTable, { TileContent } from "../../../../components/table/TileTable";
import { isMobile, isMobileOnly } from "react-device-detect";
import TableRestyle from "./TableRestyle";
import Exclamation from "../../../../assets/iconsSmall/exclamation_mark_white.svg";
import ModalEditableInvoice from "../../../claims/modals/ModalEditableInvoice";
import TileEditRowsTable from "../../../../components/table/TileEditRowsTable";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FLOW_TYPES } from "../../../../constants/Flow";

const TableContainerComponent = forwardRef(
  (
    {
      taxDetails,
      gotDetails,
      tableDetails,
      handleUpdate,
      handleNew,
      themeSetting,
      setTableDetails,
      selectedTableDetails,
      resultTableDetails,
      initialTableDetails,
      rowSelectHandler,
      updatingIndex,
      setUpdatingIndex,
      resetRowChanges,
      deleteNewLine,
      gotList,
      isOnlySelectable = false,
      onBackHandler = null,
      backHandler,
      approveTable
    },
    ref
  ) => {
    const history = useNavigate();
    const selectedFlowTypeToSkip = useSelector((s) => s.flow.selectedFlowTypeToSkip);
    const mode = useSelector((s) => s.form.mode);
    const flowType = useSelector((s) => s.flow.flowType);
    const flowQuestions = useSelector((s) => s.flow.flowQuestions);
    const flowCurrentStep = useSelector((s) => s.flow.flowCurrentStep);
    const petInfo = useSelector((s) => s.flow.selectedPetInfo);
    const [isTableCalculated, setIsTableCalculated] = useState(false);
    const [openModalEditableInvoice, setOpenModalEditableInvoice] = useState(false);
    const [isEditRows, setIsEditRows] = useState(false);
    const [showResultTable, setShowResultTable] = useState(false);


    function flowSkippedTypeHandler(type) {
      switch (type) {
        default:
        case "General":
        case "General to Vets":
        case "Liability claim":
        case "Existing Claim":
          return t("pleaseSelectClaim_G");
        case "Cost estimation":
          return t("pleaseSelectClaim_CE");
      }
    };
    // Previous version for sticky next/back

    // function approveClickHandler() {
    //   if (ref.current) {
    //     let res = resultTableDetails.map((item) => {

    //       if (item?.changedValues && Object.keys(item?.changedValues).length > 0) {
    //         let keys = Object.keys(item?.changedValues);
    //         for (let key of keys) {
    //           item[key] = item.changedValues[key];
    //         }
    //       }
    //       const { selected, changedValues, ...rest } = item;
    //       return rest;
    //     });
    //     setTableDetails(res);
    //     setTimeout(() => {
    //       ref.current.click();
    //     }, 1000);
    //   }
    // };

    function approveClickHandler() {
      let res = resultTableDetails.map((item) => {

        if (item?.changedValues && Object.keys(item?.changedValues).length > 0) {
          let keys = Object.keys(item?.changedValues);
          for (let key of keys) {
            item[key] = item.changedValues[key];
          }
        }
        const { selected, changedValues, ...rest } = item;
        return rest;
      });
      setTableDetails(res);
      setTimeout(() => {
        approveTable();
      }, 500);
    };

    useEffect(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, [openModalEditableInvoice, isEditRows, showResultTable]);

    return (
      <div className="InvoiceTable">
        {openModalEditableInvoice && (
          <ModalEditableInvoice
            taxDetails={taxDetails}
            gotDetails={gotDetails}
            tableDetails={tableDetails}
            openModalEditableInvoice={openModalEditableInvoice}
            setOpenModalEditableInvoice={setOpenModalEditableInvoice}
            setTableDetails={setTableDetails}
            initialTableDetails={initialTableDetails}
            themeSetting={themeSetting}
            handleUpdate={handleUpdate}
            selectedTableDetails={selectedTableDetails}
            resultTableDetails={resultTableDetails}
            gotList={gotList}
            handleNew={handleNew}
            petName={petInfo?.nameOfPet}
            updatingIndex={updatingIndex}
            setUpdatingIndex={setUpdatingIndex}
            resetRowChanges={resetRowChanges}
            deleteNewLine={deleteNewLine}
          />
        )}

        {(selectedTableDetails.length > 0 &&
          flowType === FLOW_TYPES["Existing Claim"]) ||
          flowType !== FLOW_TYPES["Existing Claim"]
          ? (<>
            <div className="invoice-table-title">
              {!isEditRows && !showResultTable && !isOnlySelectable && (
                <>
                  <div className="invoice-table-title__title">
                    {t("invoiceDetails")}
                  </div>
                  <div className="invoice-table-title__name">
                    {petInfo?.nameOfPet || t("Name")}
                  </div>
                </>
              )}
              {isEditRows && (
                <div className="invoice-table-title__title">
                  {t("areNotRefund")}
                </div>
              )}
              {showResultTable && (
                <div className="invoice-table-title__title">
                  {t("invoicesForVerification")}
                </div>
              )}
              {isOnlySelectable && (
                <div className="invoice-table-title__title">
                  {t("Your benefit cases")}
                </div>
              )}
            </div>

            {isEditRows
              ? !showResultTable && (
                <div
                  className="invoice-table-message column-centered"
                  style={{ margin: "5vh 0 0 0" }}
                >
                  {t("pleaseRemove")}
                </div>
              )
              : !showResultTable && (
                <>
                  <div className="invoice-table-message column-centered">
                    {isOnlySelectable
                      ? flowSkippedTypeHandler(selectedFlowTypeToSkip)
                      : t("pleaseCheckData")}
                  </div>
                </>
              )}

            <>
              {isMobile ? (
                <div className="tile-table-wrapper">
                  {!isEditRows && !isOnlySelectable && (
                    <TileTable data={tableDetails}>
                      <TileContent
                        data={
                          showResultTable
                            ? resultTableDetails
                            : selectedTableDetails
                        }
                        mode={mode}
                        showResultTable={showResultTable}
                      />
                    </TileTable>
                  )}
                  {(isEditRows || isOnlySelectable) && (
                    <TileEditRowsTable
                      rowSelectHandler={rowSelectHandler}
                      isOnlySelectable={isOnlySelectable}
                      tableData={
                        showResultTable
                          ? resultTableDetails
                          : selectedTableDetails
                      }
                      editRows={isEditRows || isOnlySelectable}
                    />
                  )}
                </div>
              ) : (
                <TableRestyle
                  taxDetails={taxDetails}
                  initialTableDetails={initialTableDetails}
                  editRows={isEditRows || isOnlySelectable}
                  showResultTable={showResultTable}
                  rowSelectHandler={rowSelectHandler}
                  updatingIndex={updatingIndex}
                  setUpdatingIndex={setUpdatingIndex}
                  selectedTableDetails={selectedTableDetails}
                  resultTableDetails={resultTableDetails}
                  gotList={gotList}
                  handleUpdate={handleUpdate}
                  isOnlySelectable={isOnlySelectable}
                  ref={ref}
                  mode={mode}
                  isTableCalculated={isTableCalculated}
                  resetRowChanges={resetRowChanges}
                />
              )}
            </>

            {isOnlySelectable ? (
              <div className="invoice-table-buttons">
                <div
                  className={`button_red_invoice ${isMobileOnly ? "mobile" : ""}`}
                  onClick={() => {
                    if (onBackHandler) {
                      onBackHandler();
                    }
                  }}
                >
                  {t("Back")}
                </div>

                <button
                  className={`button_red_invoice ${isMobileOnly ? "mobile" : ""}`}
                  disabled={
                    !selectedTableDetails?.length ||
                    selectedTableDetails?.length === resultTableDetails?.length
                  }
                  onClick={() => {
                    let res = resultTableDetails.map((item) => {
                      const { selected, ...rest } = item;
                      return rest;
                    });
                    setTableDetails(res);
                    setTimeout(() => {
                      approveTable();
                    }, 500);

                    // Previous version for "back/next" btns

                    // if (ref.current) {
                    //   let res = resultTableDetails.map((item) => {
                    //     const { selected, ...rest } = item;
                    //     return rest;
                    //   });
                    //   setTableDetails(res);
                    //   setTimeout(() => {
                    //     ref.current.click();
                    //   }, 1000);
                    // }
                  }}
                >
                  {t("Next")}
                </button>
              </div>
            ) : (
              <>
                {!isEditRows && !showResultTable && (
                  <div
                    className={`invoice-table-info ${isMobile ? "mobile" : ""}`}
                  >
                    <div>
                      <img src={Exclamation} alt="Info-image" />
                    </div>
                    {t("attention")}
                  </div>
                )}

                {!isEditRows && !showResultTable && isMobileOnly && (
                  <div className="invoice-table-buttons">
                    <button
                      className={`button_red_invoice ${isMobileOnly ? "mobile" : ""}`}
                      onClick={() => setOpenModalEditableInvoice(true)}
                    >
                      {t("correctData")}
                    </button>
                  </div>
                )}

                <div className="invoice-table-buttons">
                  {!isEditRows && !showResultTable && (
                    <button
                      className={`button_red_invoice ${isMobileOnly ? "mobile" : ""}`}
                      onClick={() => backHandler()}
                    >
                      {t("Back")}
                    </button>
                  )}
                  {!isEditRows && !showResultTable && !isMobileOnly && (
                    <button
                      className={`button_red_invoice ${isMobileOnly ? "mobile" : ""}`}
                      onClick={() => setOpenModalEditableInvoice(true)}
                    >
                      {t("correctData")}
                    </button>
                  )}
                  {isEditRows && (
                    <button
                      className={`button_red_invoice ${isMobileOnly ? "mobile" : ""}`}
                      onClick={() => {
                        setIsEditRows(false);
                        setIsTableCalculated((prev) => !prev);
                      }}
                    >
                      {t("Back")}
                    </button>
                  )}
                  {showResultTable && (
                    <button
                      className={`button_red_invoice ${isMobileOnly ? "mobile" : ""}`}
                      onClick={() => {
                        setShowResultTable(false);
                        setIsEditRows(true);
                        setIsTableCalculated((prev) => !prev);
                      }}
                    >
                      {t("Back")}
                    </button>
                  )}

                  {!isEditRows && !showResultTable && (
                    <button
                      className={`button_red_invoice ${isMobileOnly ? "mobile" : ""}`}
                      onClick={() => {
                        setIsEditRows(true);
                        setIsTableCalculated((prev) => !prev);
                      }}
                      disabled={!selectedTableDetails.length}
                    >
                      {t("Next")}
                    </button>
                  )}
                  {isEditRows && (
                    <button
                      className={`button_red_invoice ${isMobileOnly ? "mobile" : ""}`}
                      disabled={
                        !selectedTableDetails.length ||
                        !resultTableDetails?.length
                      }
                      onClick={() => {
                        setIsEditRows(false);
                        setShowResultTable(true);
                        setIsTableCalculated((prev) => !prev);
                      }}
                    >
                      {t("Next")}
                    </button>
                  )}
                  {showResultTable && (
                    <button
                      className={`button_red_invoice ${isMobileOnly ? "mobile" : ""}`}
                      disabled={!resultTableDetails.length}
                      onClick={() => {
                        approveClickHandler();
                      }}
                    >
                      {t("Next")}
                    </button>
                  )}
                </div>
              </>
            )}
          </>
          ) : (
            <div className="SlowShow show-final-body">
              <div className="invoice-table-message column-centered">
                {t("noClaimsToAdd")}
              </div>
              <div className="button_red_main" onClick={() => history("/")}>
                {t("toStartPage")}
              </div>
            </div>
          )}
      </div>
    );
  }
);

export default TableContainerComponent;
